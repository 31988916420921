/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from 'gatsby';

import Header from './header';
import '../css/fonts.css';
import '../css/style.scss';
import '../css/responsive.scss';

// social icons
import facebook from '../img/icons/facebook.svg';
import instagram from '../img/icons/instagram.svg';
import youtube from '../img/icons/youtube.svg';
import tiktok from '../img/icons/tiktok.png';
import Alert from './alert';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }

      allMysqlFooter {
        nodes {
          mysqlId
          link
          name
        }
      }
    }
  `);

  let obj = [];
  let obj2 = [];

  data.allMysqlFooter.nodes.map((d) => {
    obj.push(d.mysqlId);
    obj2[d.mysqlId] = d;
  });

  obj.sort();

  let footer = obj.map((i) => obj2[i]);

  // console.log(footer);

  return (
    <>
      <Alert
        onRef={(ref) => {
          if (typeof window !== 'undefined') {
            window.alert2 = ref;
            window.Alert = ref;
          }
        }}></Alert>

      <div className='page page-blur'>
        <Header siteTitle={data.site.siteMetadata.title} />

        <main>{children}</main>
      </div>

      <footer>
        <div className='container text-center '>
          {/* <h5 className="font-weight-bold text-white">
            Subscribe to our newsletter
          </h5>

          <form
            action=""
            onSubmit={e => e.preventDefault()}
            className="text-center"
          >
            <input
              type="email"
              placeholder="Your Email"
              className="form-control news-letter-input my-4 d-inline-block"
            />

            <button className="btn btn-primary btn-round px-5 mt-1">
              Sign Up
            </button>
          </form> */}
          <div className='py-5 text-center container position-relative'>
            <h5 className='font-weight-bold text-white'>{footer[0].name}</h5>
            <div className='mt-4'>
              {footer[1].link !== '' && (
                <a href={footer[1].link}>
                  <img src={facebook} className='social-icons mx-2' alt='' />
                </a>
              )}
              {footer[2].link !== '' && (
                <a href={footer[2].link}>
                  <img src={instagram} className='social-icons mx-2' alt='' />
                </a>
              )}
              {footer[3].link !== '' && (
                <a href={footer[3].link}>
                  <img src={youtube} className='social-icons mx-2' alt='' />
                </a>
              )}
              {footer[4].link !== '' && (
                <a href={footer[4].link}>
                  <img src={tiktok} className='social-icons mx-2' alt='' />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className='d-flex flex-row justify-content-between footer-license pb-3 px-3'>
          <small className='text-left'>{footer[7].name}</small>
          <small className='text-right'>
            <Link to={footer[5].link} className='text-light'>
              {footer[5].name}
            </Link>{' '}
            |{' '}
            <Link to={footer[6].link} className='text-light'>
              {footer[6].name}
            </Link>{' '}
            |{' '}
            {footer[8] && (
              <Link to={footer[8].link} className='text-light'>
                {footer[8].name}
              </Link>
            )}{' '}
          </small>
        </div>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
