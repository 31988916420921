import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import logo from '../img/logo.png';
import menuIcon from '../img/icons/menu.svg';
import close from '../img/icons/close.svg';

import facebook from '../img/icons/facebook_white.svg';

import instagram from '../img/icons/instagram_white.svg';
import youtube from '../img/icons/youtube_white.svg';
import whatsapp from '../img/icons/whatsapp_white.svg';
import { globalHistory } from '@reach/router';

let Header = () => {
  let path = globalHistory.location.pathname;

  const [showMenu, setshowMenu] = useState(null);

  let data = useStaticQuery(graphql`
    query MyQuery {
      allMysqlMenu {
        nodes {
          name
          link
          mysqlId
        }
      }

      allMysqlFooter {
        nodes {
          link
          name
        }
      }
    }
  `);

  let menu = data.allMysqlMenu.nodes;

  let menuIndexes = [];
  let menuItems = [];
  menu.map((d) => {
    menuItems[d.mysqlId] = d;
    menuIndexes.push(d.mysqlId);
  });
  menu = menuIndexes
    .sort((a, b) => {
      return a - b;
    })
    .map((i) => {
      return menuItems[i];
    });

  let footer = data.allMysqlFooter.nodes;

  // console.log(menu, footer);

  return (
    <>
      <div className='bg-white shadow-sm main-nav'>
        <nav className='nav container'>
          <Link to='/' className='p-3'>
            <img src={logo} alt='' className='main-logo' />
          </Link>

          <div className='desktop-menu d-flex flex-row position-relative  h-100'>
            {/* {} */}

            {menu.map((d) => (
              <Link
                className={
                  'nav-link h-100 position-relative px-4 ' +
                  (path === d.link && 'active ') +
                  (d.muted && ' muted')
                }
                disabled={d.muted}
                to={d.link}>
                {d.name}
              </Link>
            ))}
            {/* <Link
                className="nav-link h-100 position-relative px-3 "
                to="/magazine/1"
              >
                Search <Search size={18} className="ml-2"></Search>
              </Link> */}
          </div>

          <div className='nav-icons mobile-menu d-flex flex-row align-items-center'>
            <div
              onClick={() => {
                setshowMenu(!showMenu);
              }}>
              {!showMenu && <img src={menuIcon} alt='' />}
              {showMenu && <img src={close} alt='' />}
            </div>
          </div>
        </nav>

        {showMenu && (
          <div
            className='menu-dropdown p-3 d-flex flex-column align-items-center justify-content-center'
            onClick={() => {
              setshowMenu(!showMenu);
            }}>
            <div className='d-flex flex-column align-items-center'>
              {menu.map((d) => (
                <Link
                  className={'menu-link my-3 ' + (path === d.link && 'active')}
                  to={d.link}>
                  {d.name}
                </Link>
              ))}

              <div className='d-flex flex-row mt-5'>
                <a href={footer[1].link}>
                  <img src={facebook} className='social-icons mx-2' alt='' />
                </a>
                <a href={footer[2].link}>
                  <img src={instagram} className='social-icons mx-2' alt='' />
                </a>
                <a href={footer[3].link}>
                  <img src={youtube} className='social-icons mx-2' alt='' />
                </a>
                <a href={footer[4].link}>
                  <img src={whatsapp} className='social-icons mx-2' alt='' />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='ghost-nav'></div>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
